import React from 'react'
import { MainLayout } from '@cmp/site'
import { GlobalDataProvider } from '@src/data/global-data'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'

export default () => {
  const { language } = useLanguage()

  return (
    <GlobalDataProvider language={language} altUrls={[]}>
      <MainLayout className='d-flex'>
        <div className='d-flex w-100 justify-content-center align-items-center'>
          <h1>Augnablik</h1>
        </div>
      </MainLayout>
    </GlobalDataProvider>
  )
}
