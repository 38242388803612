import { useEffect } from 'react'
import { navigate } from 'gatsby'
import qs from 'qs'
import {
  useAuthentication,
  getPreviousLocation
} from '@src/context/Authentication'

const parseCookies = (cookies = document.cookie) => {
  return cookies
    .split(/;\s*/)
    .map(s => s.split('='))
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})
}

const deleteCookie = name => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
}

export default () => {
  const { setToken } = useAuthentication()

  useEffect(() => {
    const { authToken } = {
      ...parseCookies(),
      ...qs.parse(window.location.search.substring(1))
    }

    if (authToken) {
      setToken(authToken)
    }

    deleteCookie('authToken')
    navigate(getPreviousLocation() || '/', { replace: true })
  }, [])
}
